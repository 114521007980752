var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{attrs:{"id":"userOrder"}},[_c('nav',[_c('span',{class:_vm.form.status == '待处理' ? 'active' : '',on:{"click":function($event){_vm.form.status = '待处理';
        _vm.form.page = 1;
        _vm.$forceUpdate();
        _vm.getData(_vm.form);}}},[_c('span',{staticClass:"label"},[_vm._v(_vm._s(_vm.$fanyi("等待处理")))]),_c('span',{staticClass:"numberCount"},[_vm._v(_vm._s(_vm.tableTypeNum[0].num))])]),_c('span',{class:_vm.form.status == '处理中' ? 'active' : '',on:{"click":function($event){_vm.form.status = '处理中';
        _vm.form.page = 1;
        _vm.$forceUpdate();
        _vm.getData();}}},[_c('span',{staticClass:"label"},[_vm._v(_vm._s(_vm.$fanyi("处理中")))]),_c('span',{staticClass:"numberCount"},[_vm._v(_vm._s(_vm.tableTypeNum[1].num))])]),_c('span',{class:_vm.form.status == '已处理' ? 'active' : '',on:{"click":function($event){_vm.form.status = '已处理';
        _vm.form.page = 1;
        _vm.$forceUpdate();
        _vm.getData();}}},[_c('span',{staticClass:"label"},[_vm._v(_vm._s(_vm.$fanyi("历史问题")))]),_c('span',{staticClass:"numberCount"},[_vm._v(_vm._s(_vm.tableTypeNum[2].num))])])]),_c('div',[_c('div',{staticClass:"searchBox"},[_c('div',{staticClass:"myInput"},[_c('i',{staticClass:"el-icon-search"}),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.searchText),expression:"form.searchText"}],attrs:{"type":"text","placeholder":_vm.$fanyi('请输入关键词搜索')},domProps:{"value":(_vm.form.searchText)},on:{"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.getData()},"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.form, "searchText", $event.target.value)}}})]),_c('button',{staticClass:"searchBtn",on:{"click":function($event){_vm.form.page = 1;
          _vm.getData(_vm.form);}}},[_vm._v(" "+_vm._s(_vm.$fanyi("搜索"))+" ")])]),_c('div',{staticClass:"dataBody"},[_c('el-table',{staticStyle:{"width":"100%"},attrs:{"data":_vm.tableData,"border":"","header-cell-style":{
          'text-align': 'center',
          background: '#F0F0F0',
          color: 'black',
          'font-size': '12px',
          'font-weight': '500',
        },"cell-style":{ 'text-align': 'center' }}},[_c('el-table-column',{attrs:{"type":"index","width":"50","label":"Nº"}}),_c('el-table-column',{attrs:{"prop":"date","label":_vm.$fanyi('订单号'),"width":"320"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('button',{staticClass:"order_sn",on:{"click":function($event){_vm.$fun.routerToPage(
                  '/issueOrderDetail?order_sn=' +
                    scope.row.order_sn +
                    '&status=' +
                    _vm.$fun.toCode(_vm.form.status) //后端问题产品详情接口只设置了接收 待处理/处理中/已处理 其他状态也传这三个
                )}}},[_vm._v(" "+_vm._s(scope.row.order_sn)+" ")])]}}])}),_c('el-table-column',{attrs:{"prop":"name","label":_vm.$fanyi('联系负责人')},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('img',{staticClass:"lianxiren",attrs:{"src":require("../../../../assets/user-img/contacts.svg"),"alt":""},on:{"click":_vm.tocaht}})]}}])}),_c('el-table-column',{attrs:{"prop":"problem_goods_created_at","label":_vm.$fanyi('问题提出日期')}}),_c('el-table-column',{attrs:{"prop":"count","label":_vm.$fanyi('问题条数')}}),_c('el-table-column',{attrs:{"prop":"sum","label":_vm.$fanyi('问题商品数量')}})],1),_c('div',{staticClass:"allpag"},[_c('span'),_c('el-pagination',{attrs:{"current-page":_vm.form.currentPage,"page-size":10,"page-sizes":[10, 20, 30, 50],"layout":" prev, pager, next, jumper","total":_vm.total},on:{"current-change":_vm.handleCurrentChange}})],1)],1)]),_c('sideBar',{directives:[{name:"show",rawName:"v-show",value:(false),expression:"false"}],ref:"sideBar"})],1)}
var staticRenderFns = []

export { render, staticRenderFns }