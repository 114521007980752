<template>
  <div id="userOrder">
    <nav>
      <span
        @click="
          form.status = '待处理';
          form.page = 1;
          $forceUpdate();
          getData(form);
        "
        :class="form.status == '待处理' ? 'active' : ''"
        ><span class="label">{{ $fanyi("等待处理") }}</span
        ><span class="numberCount">{{ tableTypeNum[0].num }}</span></span
      ><span
        @click="
          form.status = '处理中';
          form.page = 1;
          $forceUpdate();
          getData();
        "
        :class="form.status == '处理中' ? 'active' : ''"
        ><span class="label">{{ $fanyi("处理中") }}</span
        ><span class="numberCount">{{ tableTypeNum[1].num }}</span></span
      >
      <span
        @click="
          form.status = '已处理';
          form.page = 1;
          $forceUpdate();
          getData();
        "
        :class="form.status == '已处理' ? 'active' : ''"
        ><span class="label">{{ $fanyi("历史问题") }}</span
        ><span class="numberCount">{{ tableTypeNum[2].num }}</span></span
      >
    </nav>
    <div>
      <div class="searchBox">
        <div class="myInput">
          <i class="el-icon-search"></i>
          <input
            type="text"
            v-model="form.searchText"
            @keydown.enter="getData()"
            :placeholder="$fanyi('请输入关键词搜索')"
          />
        </div>
        <button
          class="searchBtn"
          @click="
            form.page = 1;
            getData(form);
          "
        >
          {{ $fanyi("搜索") }}
        </button>
      </div>
      <!-- 表格 -->
      <div class="dataBody">
        <el-table
          :data="tableData"
          border
          style="width: 100%"
          :header-cell-style="{
            'text-align': 'center',
            background: '#F0F0F0',
            color: 'black',
            'font-size': '12px',
            'font-weight': '500',
          }"
          :cell-style="{ 'text-align': 'center' }"
        >
          <el-table-column type="index" width="50" label="Nº">
          </el-table-column>

          <el-table-column prop="date" :label="$fanyi('订单号')" width="320">
            <template slot-scope="scope">
              <button
                class="order_sn"
                @click="
                  $fun.routerToPage(
                    '/issueOrderDetail?order_sn=' +
                      scope.row.order_sn +
                      '&status=' +
                      $fun.toCode(form.status) //后端问题产品详情接口只设置了接收 待处理/处理中/已处理 其他状态也传这三个
                  )
                "
              >
                {{ scope.row.order_sn }}
              </button>
            </template>
          </el-table-column>
          <el-table-column prop="name" :label="$fanyi('联系负责人')">
            <template slot-scope="scope">
              <img
                class="lianxiren"
                src="../../../../assets/user-img/contacts.svg"
                alt=""
                @click="tocaht"
              />
            </template>
          </el-table-column>
          <el-table-column
            prop="problem_goods_created_at"
            :label="$fanyi('问题提出日期')"
          >
          </el-table-column>
          <el-table-column prop="count" :label="$fanyi('问题条数')">
          </el-table-column>
          <el-table-column prop="sum" :label="$fanyi('问题商品数量')">
          </el-table-column>
        </el-table>
        <div class="allpag">
          <span></span>
          <el-pagination
            @current-change="handleCurrentChange"
            :current-page="form.currentPage"
            :page-size="10"
            :page-sizes="[10, 20, 30, 50]"
            layout=" prev, pager, next, jumper"
            :total="total"
          >
          </el-pagination>
        </div>
      </div>
    </div>
    <sideBar v-show="false" ref="sideBar"></sideBar>
  </div>
</template>

<script>
import sideBar from "../../../../components/homePage/sideBar.vue";
export default {
  data() {
    return {
      form: {
        status: "待处理",
        page: 1,
        pageSize: 10,
        searchText: "",
      },
      tableData: [],
      total: 0,
      tableTypeNum: [
        {
          table: "待处理", //名称
          num: 0, //数量
        },
        {
          table: "处理中", //名称
          num: 0, //数量
        },
        {
          table: "已处理", //名称
          num: 0, //数量
        },
      ],
    };
  },
  components: { sideBar },
  computed: {},
  created() {
    this.getData();
    this.getTitleNumber();
  },
  methods: {
    // 打开聊天窗口
    tocaht() {
      this.$refsthis.$fun.clickJs();
    },
    // 获取种类数量数据
    getTitleNumber() {
      this.$api.problemGoodsOrderNum().then((res) => {
        //console.log("problemGoodsOrderNum", res);
        if (res.code != 0) return;
        if (res.data.length == 0) return false;
        this.tableTypeNum = res.data;
      });
    },
    // 获取数据
    getData() {
      let datas = {
        status: this.form.status,
        pageSize: this.form.pageSize,
        page: this.form.page,
        keywords: this.form.searchText,
      };
      this.$api.problemGoodsOrderList(datas).then((res) => {
        //console.log("problemGoodsOrderList", res);
        if (res.code != 0) return;
        this.tableData = res.data.data;
        this.total = res.data.total;
      });
    },
    handleSizeChange(val) {
      this.form.pageSize = val;
      this.form.pageSize = 1;
      this.getData(this.form);
    },
    handleCurrentChange(val) {
      this.form.page = val;
      this.getData(this.form);
    },
  },
};
</script>
<style lang="scss" scoped="scoped">
#userOrder {
  @import "../../../../css/mixin";
  width: 1140px;

  button {
    cursor: pointer;
  }
  > nav {
    height: 50px;
    display: flex;
    background-color: #f6f6f6;
    > span {
      display: flex;
      align-items: center;
      justify-content: center;
      // width: 130px;
      padding: 0 7px;
      height: 50px;
      background: #e8e8e8;
      border: 1px solid #e1e1e1;
      font-size: 12px;

      color: #000000;
      line-height: 26px;

      cursor: pointer;
      &.active {
        background-color: #ffffff;
        border-bottom: none;
      }
      .label {
        display: inline-block;
        margin-right: 7px;
      }
      .numberCount {
        width: 25px;
        height: 25px;
        background: #fbbb21;
        border-radius: 50%;
        font-size: 10px;
        color: #fff;
        text-align: center;
        line-height: 25px;
      }
    }
  }

  > div {
    // min-height: 720px;
    background: #ffffff;
    border: 1px solid #e1e1e1;
    border-top: none;
    padding: 20px 20px 37px 20px;
    .searchBox {
      display: flex;
      margin-bottom: 20px;
      .myInput {
        width: 400px;
        height: 40px;

        position: relative;
        display: flex;
        align-items: center;
        overflow: hidden;
        margin-right: 20px;
        > i {
          width: 20px;
          height: 24px;
          margin: 9px 11px 9px 12px;
          display: flex;
          justify-content: center;
          align-items: center;
          position: absolute;
        }
        > input {
          border: none;
          border-radius: 4px;
          border: 1px solid #c0c4cc;
          width: 500px;
          height: 100%;
          font-size: 14px;
          padding-left: 40px;
          padding-right: 10px;
          transition: 0.3s;
          &:focus {
            border-color: #1e2997;
          }
        }
      }
      /deep/.el-date-editor {
        // width: 230px;
        height: 40px;
        margin-right: 20px;
        border-radius: 4px;
        .el-range-input {
          font-size: 14px;
        }
        .el-range-separator {
          // width: 20px;
        }
      }
      .searchBtn {
        width: 80px;
        height: 40px;
        background: $homePageSubjectColor;
        border-radius: 4px;

        color: #ffffff;
        font-size: 16px;
      }
    }
    .dataBody {
      .order_sn {
        font-size: 14px;
        color: #1e2997;
        line-height: 20px;
        background-color: transparent;
      }
      .lianxiren {
        width: 32px;
        height: 32px;
        cursor: pointer;
      }
    }
    .allpag {
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      margin: 25px 0;

      > a {
        display: inline-block;
        border: 1px solid #dcdfe6;
        height: 28px;
        background-color: white;
        text-align: center;
        padding: 0 10px;
        font-size: 13px;
        line-height: 28px;
        border-radius: 2px;
      }

      /* 分页被选中的页码样式 */
      /deep/ .el-pager li {
        margin: 0 3px;
        border-radius: 4px;
        box-shadow: 0px 0px 6px 0px rgba(61, 61, 61, 0.1);

        &:hover {
          color: $homePageSubjectColor;
        }

        &.active {
          background-color: $homePageSubjectColor;
          color: white;
        }
      }
    }
  }
}
</style>
